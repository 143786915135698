<template>
  <div id="user-profile">
    <b-card>

      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Role
        </h5>
        <b-card-text>
          {{ userData.role }}
        </b-card-text>
      </div>

      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Nom
        </h5>
        <b-card-text>
          {{ userData.fullName }}
        </b-card-text>
      </div>

      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Email
        </h5>
        <b-card-text>
          {{ userData.email }}
        </b-card-text>
      </div>

      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Lien de parrainage
        </h5>
        <b-card-text class="text-success" @click="copyWallet(userData.sharedLink)">
          <a class="text-success" href="javascript:void(0)" title="Cliquer ici pour copier le lien...">{{ userData.sharedLink }}</a>
        </b-card-text>
        <b-card-text>


          <!-- AddToAny BEGIN -->
          <div class="a2a_kit a2a_kit_size_32 a2a_default_style" :data-a2a-url="userData.sharedLink">
            <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
            <a class="a2a_button_copy_link"></a>
            <a class="a2a_button_whatsapp"></a>
            <a class="a2a_button_facebook"></a>
            <a class="a2a_button_twitter"></a>
            <a class="a2a_button_telegram"></a>
            <a class="a2a_button_facebook_messenger"></a>
            <a class="a2a_button_google_gmail"></a>
            <a class="a2a_button_viber"></a>
            <a class="a2a_button_sms"></a>
          </div>
          <script type="application/javascript">
            var a2a_config = a2a_config || {};
    a2a_config.locale = "fr";
          </script>
          <script type="application/javascript" async src="https://static.addtoany.com/menu/page.js"></script>
          <!-- AddToAny END -->

        </b-card-text>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard,
  BCardText
} from 'bootstrap-vue'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    ToastificationContent,
  },
  data() {
    return {
      userData: {},
    }
  },
  created() {

    this.userData = JSON.parse(localStorage.getItem("userData"));

    console.log(this.userData)

  },
  methods: {

    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },

    copyWallet(link) {

      let text = link;

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });

      this.showToast(
        "Lien copié dans la présse papier",
        text,
        "success",
        "InfoIcon",
        "top-center",
        5000
      );

    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },

  },
}
/* eslint-disable global-require */
</script>



<!-- AddToAny END -->

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
